<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row class="mb-2">
          <b-col md="10" />
          <b-col md="2">
            <b-button
              to="/portal/polls/add"
              variant="primary"
              block
            ><FeatherIcon icon="PlusIcon" />
              Ekle</b-button>
          </b-col>
        </b-row>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
            fixed
          >
            <template #cell(question)="data">
              <div>{{ data.item.question }}</div>
            </template>
            <template #cell(status)="data">
              <div class="text-primary text-center">
                <FeatherIcon
                  :icon="data.item.status === '1'? 'CheckIcon' : 'XIcon'"
                />
              </div>
            </template>
            <template #cell(control)="data">
              <div class="text-right">
                <b-button
                  :to="'/portal/polls/result/' + data.item.id"
                  variant="outline-info"
                  size="sm"
                  class="mr-1"
                >
                  Sonuçlar
                </b-button>
                <b-button
                  :to="'/portal/polls/edit/' + data.item.id"
                  variant="outline-primary"
                  size="sm"
                >
                  Güncelle
                </b-button>
                <b-button
                  class="ml-1"
                  variant="outline-danger"
                  size="sm"
                  @click="pollDelete(data.item.id)"
                >
                  Sil
                </b-button>
              </div>
            </template>
          </b-table></b-card-text>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'question',
          label: 'Soru',
        },
        {
          key: 'status',
          label: 'Durum',
          thStyle: { width: '140px', textAlign: 'center' },
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '340px' },
        },
      ],
      dataQuery: {
        select: [
          'com_portal_polls.id AS id',
          'com_portal_polls.question AS question',
          'com_portal_polls.status AS status',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['polls/getPolls']
    },
    dataCount() {
      return this.$store.getters['polls/getPollSaveStatus']
    },
    saveStatus() {
      return this.$store.getters['polls/getPollSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
    saveStatus(val) {
      const toastData = {}
      if (val.status === true) {
        toastData.variant = 'success'
        toastData.title = 'İşlem Başarılı!'
        toastData.text = val.message
      } else {
        toastData.variant = 'danger'
        toastData.title = 'İşlem Hatası!'
        toastData.text = val.message
      }
      this.getDataList()
      this.showAlert(toastData)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    showAlert(data) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: data.title,
          icon: 'BellIcon',
          text: data.text,
          variant: data.variant,
        },
      })
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('polls/pollsList', this.dataQuery)
    },
    pollDelete(id) {
      this.$store.dispatch('polls/pollDelete', id)
    },
  },
}
</script>
